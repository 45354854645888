.team {
  margin-top: 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: luckiestGuy;
  letter-spacing: 3px;
}

.team1 {
  width: 25vw;
  height: 8vw;
  background-image: url(../assets/teams/team1.png);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2000;
}

.team2 {
  cursor: pointer;
  z-index: 2000;
  width: 25vw;
  height: 8vw;
  background-image: url(../assets/teams/team2.png);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

.team3 {
  width: 25vw;
  height: 8vw;
  background-image: url(../assets/teams/team3.png);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.team4 {
  cursor: pointer;
  width: 25vw;
  height: 8vw;
  background-image: url(../assets/teams/team4.png);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

.team5 {
  cursor: pointer;
  width: 25vw;
  height: 8vw;
  background-image: url(../assets/teams/team5.png);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

.selectTeamContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(../assets/scorebg.png);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.rowContainer {
  width: 100vw;
  display: flex;
  /* height: 100%; */
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
